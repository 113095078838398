.world
{
    position: absolute;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
    max-width: 80%;
    max-height: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform-origin: 0 0!important;
    transform: translate(-50%, -50%);
    animation: worldRotate 100s linear infinite;
}

@keyframes worldRotate {
    0%
    {
        transform: rotate(0) translate(-50%, -50%);
    }

    100%
    {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}