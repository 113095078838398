.app-form
{
    position: relative;
    width: 100%;

    &-input
    {
        position: relative;
        width: 100%;
        height: auto;

        span
        {
            font-size: 1.6em;
            user-select: none;
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translate(0, -50%);
            transition: all .1s ease;
            color: #000;

            &::after
            {
                content: ' *';
                transition: opacity .1s ease;
            }
        }
    }

    input:not(.file)
    {
        font-size: 1.6em;
        width: 100%;
        height: 50px;
        border: 0;
        border-bottom: 1px solid $primary;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        outline: 0;
        padding-left: 15px;
        color: #000;
        background: #ECF7FF;

        &:focus,
        &:valid
        {
            & ~ span
            {
                font-size: 1em;
                top: 10px;
                left: 14px;
                color: $primary;

                &::after
                {
                    opacity: 0;
                }
            }
        }
    }

    .file
    {
        font-size: 16px;
    }

    select
    {
        font-size: 1.6em;
        cursor: pointer;
        width: 100%;
        height: 50px;
        border: 0;
        border-bottom: 1px solid $primary;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        outline: 0;
        padding-left: 15px;
        padding-right: 15px;
        color: #000;
        background: #ECF7FF;
    }

    label
    {
        font-size: 1.1em;
        opacity: .5;
        color: #000;
        margin-bottom: 0;
    }
}