// For search modal
.search-wrapper {
    position: relative;
    background: #ffffff1a;
    width: 250px;
    height: 40px;
    top: 0;
    z-index: 1;
    transition: all .3s ease;
    margin-top: 50px;
    

    @include mq('tablet', max)
    {
        margin-top: 80px;
    }
      
        input {
            font-size: 1.4em;
            background: transparent;
            border: 0;
            color: white;
            outline: none;
            width: 100%;
            height: 40px;
            padding: 0 40px 0 13px;
            font-weight: 100;
            transition: 275ms ease;
            
            &::placeholder {
                color: #cccccc;
            }
    }
}

#main
{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
    transition: all .2s ease;

    p
    {
        position: relative;
        margin: 30px 0;

        &::before
        {
            position: absolute;
            content: '';
            width: 80%;
            height: 1px;
            bottom: -15px;
            left: 10%;
            background: linear-gradient(80deg, rgba(255,255,255,0) 0%, #ffffffad 50%, rgba(255,255,255,0) 100%);
        }
    }
}

.search-input {
    width: 300px;
    max-width: 100%;
    border: none;
    outline: 0;
    background: #eee;
    border-radius: 4px;
    padding: 10px 30px;
}

// My dropdown
.app-drop
{
    position: relative;

    &-content
    {
        position: absolute;
        visibility: hidden;
        width: auto;
        min-width: 100%;
        max-width: 200%;
        height: auto;
        top: 100%;
        left: 50%;
        padding-top: 20px;
        opacity: 0;
        transform: translateX(-50%);

        p
        {
            line-height: 1;
            padding-bottom: 15px;
            margin: 0;
            
            a
            {
                font-weight: 300!important;
                font-size: 15px;

                &::after
                {
                    display: none;
                }

                &:hover
                {
                    font-weight: 500!important;
                }
            }
        }

        &.top
        {
            top: 0;
            padding-top: 0;
            padding-bottom: 5px;
            transform: translateY(calc(-100%));

            p
            {
                padding: 0;
                padding-top: 15px;
                margin: 0;
            }
        }
    }

    &:hover
    {
        .app-drop-content
        {
            visibility: visible;
            opacity: 1;
            transition: all .4s ease;
        }
    }
}

// Section title
.sec-tle
{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3.8em;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding-bottom: 55px;
    color: $purple;

    span
    {
        padding-left: 50px;
    }

    // @include mq('desktop', max)
    // {
    //     font-size: 4.2em;
    // }

    @include mq('tablet-mid', max)
    {
        font-size: 3.2em;

        span
        {
            padding-left: 25px;
        }
    }

    @include mq('tablet', max)
    {
        text-align: center;
        display: inline-block;
        padding-bottom: 30px;

        span
        {
            padding-left: 0;
        }
    }

    @include mq('phablet', max)
    {
        font-size: 2.4em;
    }
}

// Link to pages
.link
{
    font-weight: bold;
    font-size: 1.9em;
    display: inline-block;
    align-items: center;
    color: #1B75BC!important;

    i
    {
        padding-left: 10px;
        transition: padding-left .2s ease;
    }

    &:hover
    {
        i
        {
            padding-left: 15px;
        }
    }
}

// Section bg text
.bg-text
{
    text-transform: uppercase;
    text-align: right;
    font-weight: bold;
    font-size: 19.1vw;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    right: 30px;
    z-index: -1;
    opacity: .1;
    transform: translateY(-50%);

    &.top
    {
        top: 0;
        transform: none;
    }
}

// Text in other pages
.tle-text
{
    p
    {
        font-size: 1.8em;
    }
}

// Tabs
.wrapper
{
    .tabs
    {
        position: relative;
        display: flex;
        margin: 70px 0;

        .tab
        {
            font-size: 1.5em;
            position: relative;
            display: inline-block;
            cursor: pointer;
            margin-right: 30px;
            transition: all .1s ease;
            color: $purple;
            
            &::before
            {
                content: '';
                position: absolute;
                width: 0%;
                height: 3px;
                bottom: -13px;
                left: 0;
                transition: all .3s ease .1s;
                background: $primary;
            }

            &.active
            {
                font-weight: bold;
                font-size: 1.8em;

                &::before
                {
                    width: 30%;
                }
            }
        }

        &::before
        {
            content: '';
            position: absolute;
            width: 3000px;
            height: 2px;
            bottom: -15px;
            left: 0;
            background: $primary;
        }

        @include mq('desktop-small', max)
        {
            flex-wrap: wrap;
            justify-content: space-around;

            .tab
            {
                margin: 0 .8rem;

                &::before
                {
                    display: none;
                }
            }
        }

        @include mq('tablet', max)
        {
            justify-content: space-between;
        }
    }
    
    .tab_item 
    {
        display: none;

        &.active
        {
            display: block;
        }
    }
}

// Bootstrap modal
.close
{
    outline: none!important;
    border: 0;
}