main
{
    position: relative;
    width: 100%;
    height: auto;

    section
    {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 75px;

        &.py
        {
            padding: 75px 0;
        }

        &.mt
        {
            padding-top: 0;
            margin-top: 75px;
        }

        &.my
        {
            padding: 0;
            margin: 75px 0;
        }

        &.outer
        {
            margin: 0;
            margin-top: 75px;

            &.py
            {
                margin: 0;
                margin: 75px 0;
            }
        }

        @include mq('tablet', max)
        {
            padding-top: 75px;

            &.py
            {
                padding: 75px 0;
            }

            &.mt
            {
                margin-top: 75px;
            }

            &.my
            {
                margin: 75px 0;
            }

            &.outer
            {
                margin: 0;
                margin-top: 75px;

                &.py
                {
                    margin: 0;
                    margin: 75px 0;
                }
            }
        }
    }

    // Main page

    .intro
    {
        overflow: hidden;
        min-height: 100vh;
        height: auto;
        background: url(../img/intro-bg.jpg) no-repeat;
        background-size: cover;
        background-position: center center;

        &-item
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            min-height: 100vh;
            padding: 130px 0;
            padding-top: 250px;
            z-index: 1;
            color: #fff;

            h1
            {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 4em;
                margin-bottom: 30px;
            }
            
            p
            {
                font-size: 1.8em;
                margin-bottom: 30px;
            }

            .app-search
            {
                font-weight: bold;
                font-size: 1.5em;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                
                .app-drop
                {
                    position: relative;
                    top: 9px;

                    &-content
                    {
    
                        P
                        {
                            font-size: 14px;
                        }
                    }
                }

                &-input
                {
                    display: flex;
                    align-items: center;
                    padding-left: 15px;

                    input
                    {
                        border: 0;
                        border-bottom: 1px solid #fff;
                        outline: 0;
                        color: #fff;
                        background: transparent;
                    }

                    i
                    {
                        font-size: 1.2em;
                        padding-left: 5px;
                    }
                }
            }

            @include mq('tablet-mid', max)
            {
                h1
                {
                    font-size: 3.2em;
                }

                p
                {
                    font-size: 1.6em;
                }
            }

            @include mq('tablet', max)
            {
                .col-md-6
                {
                    text-align: center;
                }

                h1
                {
                    & > *
                    {
                        padding: 0!important;
                    }
                }

                .app-search
                {
                    justify-content: center;
                    padding-top: 30px;
                }
            }
        }
    }

    .success-years
    {
        font-weight: 400;

        p
        {
            font-size: 1.8em;
            margin-bottom: 30px;
        }

        @include mq('tablet', max)
        {
            text-align: center;

            p
            {
                margin-bottom: 20px;
            }
        }
    }

    .news
    {
        .row
        {
            .col-md-5
            {
                margin-top: 120px;

                &:nth-child(even)
                {
                    margin-top: 0;
                }

                @include mq('tablet', max)
                {
                    margin-top: 20px;
                    
                    &:nth-child(even)
                    {
                        margin-top: 20px;
                    }
    
                    &:first-child
                    {
                        margin: 0;
                    }
                }
            }
        }

        p.main
        {
            text-align: right;

            a
            {
                font-size: 2.2em;
            }
        }
    }

    .callback
    {
        .col-md-5
        {
            margin-bottom: 30px;
        }
    }

    // Other Pages

    &.about-company-page
    {
        .box
        {
            h2
            {
                padding-bottom: 20px;
            }
            
            p
            {
                font-size: 1.8em;
                padding-left: 50px;
            }

            @include mq('tablet-mid', max)
            {
                p
                {
                    padding-left: 35px;
                }
            }

            @include mq('tablet', max)
            {
                p
                {
                    text-align: center;
                    padding-left: 0;
                }
            }
        }

        .world-map
        {
            background: url('../img/world-map-bg.jpg') no-repeat;
            background-size: cover;
            background-position: center center;

            .container
            {
                position: relative;
            }

            &-svg
            {
                width: 100%;
                height: 800px;

                .country
                {
                    fill: #fff;
                    transition: all .3s ease;

                    &:hover
                    {
                        fill: #00345a;
                    }
                }
            }

            .coutry-titles
            {
                font-size: 1.5em;
                display: flex;
                justify-content: space-between;
                position: absolute;
                right: 0;
                bottom: -15px;
                color: #fff;

                li
                {
                    list-style-type: none;
                    user-select: none;
                    font-size: 16px;

                    &::before
                    {
                        font-family: 'Font Awesome 5 Pro';
                        content: '\f3c5';
                        font-weight: 900;
                        font-size: 0.8em;
                        margin-right: 5px;
                    }
                }
            }

            @include mq('tablet', max)
            {
                &-svg
                {
                    height: 290px;
                }

                .coutry-titles
                {
                    display: flex;
                    justify-content: center;
                    position: static;
                    padding-top: 20px;

                    .col-6
                    {
                        flex: 0;
                        width: auto;
                    }

                    ul
                    {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        .mission
        {
            overflow: hidden;

            .bg-img
            {
                position: absolute;
                object-fit: cover;
                object-position: center center;
                width: 30%;
                height: 750px;
                top: 300px;
                left: 0;

                &.right
                {
                    left: auto;
                    right: 0;
                }

                &.top
                {
                    top: 0;
                }

                @include mq('tablet', max)
                {
                    display: none;
                }
            }

            .container:not(.main)
            {
                position: relative;
                padding: 75px 100px;
                z-index: 1;
                color: #fff;
                margin-top: 75px;
                background: #1b75bcde;

                h2
                {
                    color: #fff;
                }

                @include mq('phablet', max)
                {
                    padding: 50px 15px;
                }
            }

            @include mq('tablet', max)
            {
                padding-bottom: 0;
            }
        }

        .advantages
        {
            .row
            {
                justify-content: center;
            }
        }
    }

    &.trading-page
    {
        .trading
        {
            .col-md-6
            {
                position: static;

                &:last-child
                {
                    padding-left: 50px;
                }
            }

            h2
            {
                padding-bottom: 30px;
            }

            p
            {
                font-size: 1.6em;

                i
                {
                    font-weight: 500;
                    font-style: italic;
                    display: block;
                    padding-left: 40px;
                    opacity: .8;

                    a
                    {
                        color: $primary;
                    }
                }
            }

            .app-btn
            {
                margin-left: 40px;
            }

            .box
            {
                position: absolute;
                display: flex;
                justify-content: space-between;
                width: 50%;
                height: 100%;
                top: 0;
                left: 0;

                img
                {
                    object-fit: cover;
                    max-height: 100%;
                    height: auto;

                    &:first-child
                    {
                        object-position: right bottom;
                        width: 50%;
                        margin-top: 200px;
                    }
                    
                    &:last-child
                    {
                        object-position: left bottom;
                        width: 35%;
                    }
                }
            }

            @include mq('tablet', max)
            {
                text-align: center;
                
                .col-md-6
                {
                    &:first-child
                    {
                        display: none;
                    }
                    
                    &:last-child
                    {
                        padding-left: 0;
                    }
                }

                p i
                {
                    padding-left: 0;
                }

                .app-btn
                {
                    margin: 0;
                }
            }
        }
    }

    &.news-item-page
    {
        .news-item
        {
            img
            {
                width: 100%;
                height: auto;
                margin-bottom: 80px;
            }

            h2:not(.main)
            {
                font-size: 2.4em;
                padding: 0;
                margin-bottom: 30px;
            }

            .box
            {
                margin-bottom: 50px;
                
                span
                {
                    font-weight: bold;
                    font-size: 1.8em;
                    margin-bottom: 20px;
                }

                &:last-of-type
                {
                    margin: 0;
                }
            }

            p,
            li
            {
                font-size: 16px;
            }

            p.column
            {
                font-size: 1.8em;
                column-count: 1;
                column-gap: 60px;
                margin-bottom: 30px;
            }

            i.main
            {
                font-size: 1.8em;
                position: relative;
                display: flex;
                flex-direction: column;
                width: 55%;
                margin: 0 auto 100px;
                color: $primary;

                b
                {
                    margin-top: 20px;
                    color: $purple;
                }
                
                &::before
                {
                    content: '“';
                    font-weight: bold;
                    font-size: 30em;
                    font-style: normal;
                    line-height: .7;
                    position: absolute;
                    user-select: none;
                    top: 0;
                    right: 0;
                    opacity: .1;
                    z-index: -1;
                    color: #000;
                }
            }

            p.italic
            {
                font-style: italic;
                font-weight: 500;
                font-size: 1.8em;
                margin-bottom: 30px;
                opacity: .7;
            }

            @include mq('tablet', max)
            {
                img
                {
                    object-fit: cover;
                    height: 350px;
                    margin-bottom: 30px;
                }

                .box
                {
                    padding-left: 0;

                    span
                    {
                        text-align: right;
                        font-size: 1.6em;
                        display: block;
                        width: 100%;
                    }
                }

                h2:not(.main)
                {
                    font-size: 1.8em;
                }

                p.column,
                p.italic
                {
                    font-size: 1.6em;
                }

                i.main
                {
                    text-align: center;
                    font-size: 1.6em;
                    width: 100%;
                    margin-bottom: 30px;
                }

                p.column
                {
                    column-count: 1;
                }
            }

            &.second
            {
                img
                {
                    height: 300px;
                    object-fit: cover;
                    object-position: center;
                }
            }

        }
    }

    &.vendor-item-page
    {
        .vendor-item
        {
            .box
            {
                .back
                {
                    font-weight: bold;
                    font-size: 1.9em;
                    display: block;
                    width: 100%;
                    color: $primary;
                    margin-bottom: 50px;

                    i
                    {
                        margin-right: 10px;
                    }
                }

                img
                {
                    width: 450px;
                    max-width: 100%;
                    margin-bottom: 50px;
                }

                p
                {
                    font-size: 1.8em;
                    margin-bottom: 20px;
                }

                .links
                {
                    user-select: none;
                    pointer-events: none;
                    font-size: 1.8em;
                    color: $primary;
                    margin: 30px 0 100px;

                    a
                    {
                        margin-right: 20px;

                        &.active
                        {
                            text-decoration: underline;
                        }
                    }
                }

                .downloads
                {
                    h3
                    {
                        font-size: 2.4em;
                        margin-bottom: 30px;
                    }

                    .download
                    {
                        font-weight: bold;
                        font-size: 1.5em;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    
                    a
                    {
                        display: flex;
                        align-items: center;
                        padding: 10px 20px;
                        margin-right: 50px;
                        margin-bottom: 15px;
                        color: $primary;
                        background: rgba(0, 48, 86, 0.1);

                        img
                        {
                            width: 22px;
                            margin: auto 0;
                            margin-right: 10px;
                        }
                    }
                }

                @include mq('tablet', max)
                {
                    .downloads,
                    .links
                    {
                        text-align: center;
                    }

                    .links
                    {
                        margin: 20px 0 50px;
                    }
                }
            }
        }
        
        .video
        {
            iframe
            {
                margin-bottom: 30px;
            }

            .date
            {
                font-weight: bold;
                font-size: 1.8em;
                display: block;
                padding-left: 30px;
                margin-bottom: 30px;
            }

            .title
            {
                font-size: 2.4em;
                padding-bottom: 10px;
            }

            p
            {
                font-size: 1.8em;
                padding-left: 30px;
            }

            @include mq('tablet', max)
            {
                iframe
                {
                    margin-bottom: 30px;
                }

                .date
                {
                    font-size: 1.6em;
                    margin-bottom: 30px;
                    padding-left: 0;
                }

                .title
                {
                    font-size: 2em;
                }

                p
                {
                    font-size: 1.6em;
                    padding-left: 0;
                }
            }
        }
    }
    
    .region
    {
        text-align: center;

        .col-lg-2
        {
            font-weight: bold;
            font-size: 1.8em;
            margin-bottom: 30px;

            img
            {
                width: 100%;
                margin-bottom: 25px;
            }
        }

        hr
        {
            margin: 50px 0 70px;
            background: $purple;
        }
    }
    
    &.contacts-page
    {
        .contacts
        {
            .tab_content
            {
                h3
                {
                    font-weight: bold;
                    font-size: 2.4em;
                    margin-bottom: 50px;
                }

                p
                {
                    font-size: 1.8em;
                    margin-bottom: 20px;
                }

                img
                {
                    width: 75px;
                    margin-bottom: 50px;
                }

                .next
                {
                    font-weight: bold;
                    font-size: 1.9em;
                    display: block;
                    width: 100%;
                    color: $primary;
                    margin-bottom: 50px;

                    i
                    {
                        padding-left: 10px;
                    }
                }

                @include mq('tablet-mid', max)
                {
                    h3
                    {
                        font-size: 2em;
                        margin-bottom: 30px;
                    }

                    p
                    {
                        font-size: 1.6em;
                    }

                    img
                    {
                        margin-bottom: 30px;
                    }
                }
            }

            .tabs
            {
                justify-content: flex-start;
            }
        }
    }

    &.page
    {
        padding-top: 140px;
    }
}

footer
{
    position: relative;
    overflow: hidden;
    padding: 100px 0;
    background: url('../img/footer-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center center;

    h2
    {
        padding-bottom: 20px!important;
    }

    p
    {
        font-size: 1.8em;
        margin-bottom: 40px;
        color: #fff;
    }

    ul
    {
        text-align: right;
        padding: 0;
        margin: 0;

        li
        {
            list-style-type: none;
            margin-top: 10px;

            &:last-child
            {
                margin-top: 50px;

                a
                {
                    position: relative;

                    &::after
                    {
                        position: absolute;
                        content: '';
                        width: 35%;
                        height: 3px;
                        bottom: -10px;
                        left: 0;
                        background: #fff;
                    }
                }
            }
        }

        a
        {
            font-size: 1.8em;
            position: relative;
            color: #fff!important;

            &::after
            {
                position: absolute;
                content: '';
                width: 0;
                height: 3px;
                bottom: -8px;
                left: 0;
                transition: all .3s ease;
                background: #fff;
            }
            
            &:hover
            {
                font-weight: 500;

                &::after
                {
                    width: 50%;
                }
            }
        }
    }

    @include mq('tablet', max)
    {
        text-align: center;

        p
        {
            font-size: 1.6em;
            margin-bottom: 30px;
        }

        ul
        {
            text-align: center;
            margin-top: 30px;
        }
    }
}