header
{
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    padding: 100px 0;
    z-index: 4;
    transition: all .3s ease;

    nav
    {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
    }

    &.active
    {
        padding: 10px 0;
        background: rgba(0, 0, 0, 0.578);
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);

        .brand
        {
            height: 80px;
        }
    }

    .brand
    {
        transition: all .3s ease;
    }

    ul.main
    {
        position: relative;
        display: flex;
        width: auto;
        color: #fff;
        padding: 0;
        margin: 0;
        margin-left: 1rem;

        li
        {
            position: relative;
            display: flex;
            align-items: center;
            list-style: none;
            margin-left: 2rem;

            a
            {
                position: relative;
                font-size: 1.5em;
                transition: all .2s linear;

                &::after
                {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 3px;
                    bottom: -11px;
                    left: 0;
                    transition: all .3s ease;
                    background: #fff;
                }
                
                &.active
                {
                    font-weight: 500;
                    
                    &::after
                    {
                        width: 50%;
                    }
                }
            }

            i
            {
                font-size: 1.4em;
                cursor: pointer;
            }
            
            &:hover a
            {
                font-weight: 500;
    
                &::after
                {
                    width: 50%;
                }
            }

            &:first-child::before
            {
                position: absolute;
                content: '';
                width: 3000px;
                height: 3px;
                bottom: -10px;
                left: 0;
                background: #fff; 
            }
        }

        .app-drop-content
        {
            a
            {
                font-size: 1.5em;
            }
        }

        @include mq('desktop', max)
        {
            li
            {
                margin-left: 1rem;
                
                a
                {
                    font-size: 1.8em;
                }
            }
        }

        @include mq('tablet-mid', max)
        {
            display: none;
        }
    }

    &.primary
    {
        // position: relative;
        padding: 10px 0;
        background: rgba(255, 255, 255, 0.578);
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);

        nav
        {
            align-items: flex-end;
        }

        .app-drop-content
        {
            padding: 10px;
            padding-top: 20px;
            padding-bottom: 0;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            background: #fff;
        }

        ul.main
        {
            margin-bottom: 9px;
            color: $primary;

            li
            {
                a
                {
                    &::after
                    {
                        background: $primary;
                    }
                }

                &:first-child::before
                {
                    background: $primary; 
                }
            }
        }
    }

    @include mq('tablet-mid', max)
    {
        padding: 20px 0 0;
        z-index: 10;

        .brand
        {
            height: 70px;
        }
    }
}

.mob-nav
{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
    transform: translateY(-100%);
    transition: all .4s ease;
    background: #000000e0;

    ul
    {
        padding: 0;
        padding: 100px 15px;
        margin: 0;

        li
        {
            cursor: pointer;
            text-align: center;
            font-size: 2.4em;
            line-height: 1.8;
            list-style-type: none;
            color: #fff;

            a
            {
            }
        }
    }
    
    .collapse-item
    {
        text-align: center;
        font-size: 14px;
        position: relative;
        display: none;
        width: 100%;
        height: auto;
        padding: 15px 0;
        color: #fff;

        p
        {
            margin-bottom: 10px;
        }
    }

    &.active
    {
        transform: translateY(0);
    }
}