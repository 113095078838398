.ham {
    width: 50px;
    height: 35px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

  &.primary
  {
    span
    {
      background: $primary;
    }
  }

    @include mq('tablet-mid', min)
    {
        display: none;
    }
  }

  .ham span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: #fff;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .15s ease;
    -moz-transition: .15s ease;
    -o-transition: .15s ease;
    transition: .15s ease;
  }
  
  .ham span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  
  .ham span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }
  
  .ham span:nth-child(1), .ham span:nth-child(2) {
    top: 0px;
  }
  
  .ham span:nth-child(3), .ham span:nth-child(4) {
    top: 16px;
  }
  
  .ham span:nth-child(5), .ham span:nth-child(6) {
    top: 33px;
  }
  
  .ham.active span:nth-child(1),.ham.active span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .ham.active span:nth-child(2),.ham.active span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .ham.active span:nth-child(1) {
    left: 5px;
    top: 9px;
  }
  
  .ham.active span:nth-child(2) {
    left: calc(50% - 5px);
    top: 9px;
  }
  
  .ham.active span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  .ham.active span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  .ham.active span:nth-child(5) {
    left: 5px;
    top: 24px;
  }
  
  .ham.active span:nth-child(6) {
    left: calc(50% - 5px);
    top: 24px;
  }