.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-1,
.swiper-3,
.swiper-4
{
    .swiper-wrapper
    {
        width: calc(100% - 1px);
        top: -1px;
        right: -3px;
        
        .swiper-slide-active
        {
            .bag-cards
            {
                border-left: 0;
            }
        }
    }

    .swp-dots
    {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .swiper-pagination-bullet
        {
            width: 12px;
            height: 12px;
            margin: 0 7px;
            opacity: 1!important;
            border: 1px solid #1B75BC;
            background: transparent;

            &-active
            {
                background: #1B75BC;
            }
        }
    }
}

.swiper-2
{
    margin-top: 100px;

    .swiper-wrapper
    {
        position: relative;
    }

    .swiper-slide
    {
        transition: all .3s ease;
        
        p
        {
            font-weight: 300;
            font-size: 1.6em;
            margin: 0;
            transition: all .3s ease;
        }
        
        .slide-tle
        {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            top: 0;
            margin-bottom: 50px;
            transition: all .3s ease;
    
            span
            {
                position: relative;
                display: inline-block;
                font-weight: 500;
                font-size: 1.6em;
                opacity: 1;
                transition: all .3 ease;
                color: #fff;

                
                &:last-child
                {
                    height: 23.33px;

                    &::before
                    {
                        position: absolute;
                        content: '';
                        width: 0%;
                        height: 3px;
                        top: calc(100% + 5px);
                        left: 0;
                        background: #fff;
                        transition: all .3s ease;
                        transition-delay: .3s;

                        @include mq('phablet', max)
                        {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        &.swiper-slide-active .slide-tle span:last-child::before
        {
            width: 30%;
        }

        &.swiper-slide-prev
        {
            opacity: 0;
        }

        &:not(.swiper-slide-active)
        {
            p
            {
                opacity: 0;
            }

            .slide-tle
            {
                top: 24px;
                span:first-child
                {
                }
                
                span:last-child
                {
                    opacity: 0;
                }
            }
        }
    }

    .swp-dots
    {
        font-size: 2em;
        position: absolute;
        top: 65px;
        right: 0;
        z-index: 1;
        transition: all .3s ease;
        color: #fff;

        i
        {
            margin-left: 10px;

            &.swiper-button-disabled
            {
                opacity: .5;
            }
        }

        
        @include mq('phablet', max)
        {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    
    @include mq('phablet', min)
    {
        .swiper-wrapper
        {
            left: -127.5px;
        }
    }

    @include mq('tablet', min)
    {
        .swiper-wrapper
        {
            left: -258.75px;
        }
    }
    
    @include mq('tablet-mid', min)
    {
        .swiper-wrapper
        {
            left: -348.75px;
        }
    }

    @include mq('desktop-small', min)
    {
        .swiper-wrapper
        {
            left: -416.25px;
        }
    }

    &::before
    {
        position: absolute;
        content: '';
        width: 100%;
        height: 3px;
        top: 55px;
        left: 0;
        background: #fff;
    }
}

.introSlider
{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    .swiper-pagination
    {
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);

        .swiper-pagination-bullet
        {
            margin: 0 2px;
            background: #fff;

        }
    }

    img
    {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &~.container
    {
        z-index: 2;
    }
}