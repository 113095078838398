.years-cards
{
    display: flex;
    align-items: center;
    margin-top: 50px;

    img
    {
        object-fit: contain;
        object-position: center center;
        width: 90px;
        height: 90px;
        margin-right: 15px;
    }

    h2
    {
        font-weight: bold;
        font-size: 3.8em;
        line-height: .8;
        margin-bottom: 0;
    }

    span:not(.main)
    {
        font-size: 2em;
    }

    &.main
    {
        @include mq('tablet', min)
        {
            margin-top: 0;
    
            img
            {
                margin-right: 30px;
            }
    
            h2
            {
                font-size: 7em;
            }
        }
    }

    @include mq('desktop-small', max)
    {
        img
        {
            width: 80px;
            height: 80px;
        }

        // h2
        // {
        //     font-size: 4.2em;
        // }

        span
        {
            font-size: 1.8em;
        }
    }

    @include mq('tablet', max)
    {
        justify-content: center;
    }
}

.bag-cards
{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    .bag-card
    {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 5);
        height: 200px;
        bottom: -1px;
        right: -1px;
        padding: 0 15px;
        border-bottom: 1px solid $purple;
        border-right: 1px solid $purple;
        transition: background .3s ease;

        img
        {
            object-fit: contain;
            object-position: center center;
            max-width: 100%;
            width: 120px;
            max-height: 100px;
        }

        &:hover
        {
            background: #00305624;
        }

        @include mq('tablet-mid', max)
        {
            width: calc(100% / 3);
        }

        @include mq('phablet', max)
        {
            width: 50%;
            height: 120px;
        }
    }
}

.bag-cards.main
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    padding: 0 15px;
    border-top: 1px solid $purple;
    border-left: 1px solid $purple;
    transition: background .3s ease;

    img
    {
        object-fit: contain;
        object-position: center center;
        max-width: 100%;
        width: 120px;
        max-height: 100px;
    }

    &:hover
    {
        background: #00305624;
    }

    @include mq('phablet', max)
    {
        height: 120px;
    }
}

.news-cards
{
    width: 100%;
    height: auto;

    img
    {
        object-fit: cover;
        object-position: center top;
        width: 100%;
        height: 500px;
    }

    .date
    {
        font-weight: bold;
        font-size: 1.8em;
        display: inline-block;
        margin: 20px 0;
        padding-left: 30px;
    }

    h2
    {
        font-size: 2.4em;
        padding: 0;
        margin-bottom: 20px;

        span
        {
            padding-left: 30px;
        }
    }

    h4
    {
        font-weight: 400;
        font-size: 1.8em;
        padding-left: 30px;
    }

    @include mq('tablet-mid', max)
    {
        img
        {
            height: 300px;
        }

        .date
        {
            font-size: 1.6em;
        }

        h2
        {
            font-size: 2em;
        }

        h4
        {
            font-size: 1.6em;
        }

        a
        {
            font-size: 1.6em;
        }
    }

    @include mq('tablet', max)
    {
        .date,
        h4
        {
            padding-left: 0;
        }

        h2 span
        {
            padding-left: 0;
        }
    }
}

// Other Pages

.mission
{

    .col-lg-4
    {
        margin-bottom: 50px;

        img
        {
            object-fit: contain;
            object-position: center center;
            width: 80px;
            height: 80px;
            margin-bottom: 20px;
        }

        h4
        {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.4em;
            margin-bottom: 10px;
        }

        p
        {
            font-size: 1.5em;
            margin-bottom: 0;
        }

        @include mq('tablet', max)
        {
            text-align: center;
            margin-bottom: 20px;

            img
            {
                width: 70px;
                height: 70px;
                margin-bottom: 10px;
            }

            h4
            {
                font-size: 2em;
            }
        }
    }
}

.advantages
{
    .col-md-6
    {
        margin-bottom: 50px;

        img
        {
            object-fit: contain;
            object-position: left bottom;
            width: 100px;
            height: 100px;
            margin-bottom: 15px;
        }

        h4
        {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.4em;
            margin-bottom: 15px;
        }

        p
        {
            font-size: 1.8em;
            line-height: 1;
            padding-left: 25px;
            margin-bottom: 0;
        }

        @include mq('tablet', max)
        {
            text-align: center;
            margin-bottom: 20px;

            img
            {
                width: 80px;
                height: 80px;
            }

            h4
            {
                font-size: 2em;
            }

            p
            {
                font-size: 1.6em;
                padding-left: 0;
            }
        }
    }
}

.subdivisions
{
    
    .row
    {
        .col-12
        {
            position: relative;
            display: flex;
            padding-left: 100px;
            margin-top: 50px;
    
            div
            {
                padding-left: 30px;
            }

            img
            {
                object-fit: contain;
                object-position: left top;
                width: 100px;
                height: 100px;
            }

            h4
            {
                font-weight: bold;
                font-size: 2.4em;
                margin-bottom: 10px;
            }

            p
            {
                font-size: 1.8em;
            }

            @include mq('tablet', min)
            {
                &::before
                {
                    content: '';
                    position: absolute;
                    width: 60px;
                    height: 10px;
                    top: 40px;
                    left: -30px;
                    z-index: 1;
                    background: url('../img/sbd-line.png') no-repeat;
                    background-size: cover;
                    background-position: left top;
                }

                &::after
                {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 150%;
                    top: -30%;
                    left: -26px;
                    background: #fff;
                }

                &:first-child
                {
                    padding-left: 230px;
        
                    &::before
                    {
                        top: 50%;
                        width: 230px;
                        transform: translateY(-50%);
                    }
    
                    &::after
                    {
                        top: 50%;
                    }
                }

                &:last-child
                {
                    &::after
                    {
                        height: 100%;
                        top: auto;
                        bottom: calc(100% - 40px);
                    }
                }
            }

            @include mq('tablet', max)
            {
                text-align: center;
                flex-direction: column;
                align-items: center;
                padding-left: 15px;
                
                img
                {
                    margin-bottom: 10px;
                }
                
                div
                {
                    padding: 0;
                }
            }

            @include mq('phablet', max)
            {

                h4
                {
                    font-size: 2em;
                }

                p
                {
                    font-size: 1.6em;
                }
            }
        }
    }
}

.vacancies
{
    .row
    {
        .col-md-6
        {
            position: relative;
            top: 1px;
            right: -1px;
            padding: 60px;
            border-right: 1px solid $purple;
            border-bottom: 1px solid $purple;
        }

        h2
        {
            font-size: 2.4em;
            height: 55.56px!important;
            padding: 0;
            margin-bottom: 20px;
            @include textClamp(2);
        }

        p
        {
            font-size: 1.8em;
            height: 80px!important;
            margin-bottom: 50px;

            @include textClamp(3);
        }

        @include mq('tablet', max)
        {
            text-align: center;

            .col-md-6
            {
                padding: 50px 15px;
            }

            h2
            {
                font-size: 2em;
            }

            p
            {
                font-size: 1.6em;
                margin-bottom: 30px;
            }
        }
    }
}

.products
{
    .row.overflow-hidden
    {
        .col-lg-6
        {
            position: relative;
            top: 1px;
            right: -2px;
            padding: 60px;
            border-right: 1px solid $purple;
            border-bottom: 1px solid $purple;
        }

        .d-flex
        {
            align-items: flex-end;
        }

        img
        {
            object-fit: contain;
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }

        h2
        {
            font-size: 2.4em;
            padding-bottom: 0;
        }

        p
        {
            font-size: 1.8em;
            margin-bottom: 50px;
            transition: all 3s ease;

            @include textClamp(2);

            &.active
            {
                @include textClamp(50);
            }
        }

        @include mq('tablet-mid', max)
        {
            text-align: center;

            .col-lg-6
            {
                padding: 50px 15px;
            }

            .d-flex
            {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            img
            {
                margin: 0;
                margin-bottom: 20px;
            }

            h2
            {
                font-size: 2em;
            }
        }
    }
}