@charset "UTF-8";
@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro-Light.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro.woff) format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro-Italic.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro-Medium.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro-MediumItalic.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro-Bold.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "gotham-pro";
  src: url(../fonts/GothamPro/GothamPro-Black.ttf) format("truetype"), url(../fonts/GothamPro/GothamPro-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal; }

/**
  Переменные для цветов
 */
*,
::after,
::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  font-family: "gotham-pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: left;
  font-size: 0.625rem;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
  margin: 0;
  line-height: 1.5;
  color: #003056;
  background-color: #fff; }
  body.active {
    height: 100vh; }

[tabindex='-1']:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0;
  outline: 0; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button; }

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer; }

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: #767676d7; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; }

.app-btn {
  font-weight: bold;
  font-size: 1.4em;
  letter-spacing: 1px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: auto;
  height: 44px;
  padding: 0 20px;
  padding-right: 54px;
  border: 1px solid #fff;
  border-right: 0;
  border-radius: 5px;
  outline: none !important;
  z-index: 1;
  color: #E5E5E5;
  background: transparent;
  transition: all 0.2s cubic-bezier(0.1, 1.26, 0.57, 1.32); }
  .app-btn i {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    top: 0;
    right: -2px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #1B75BC;
    background: #fff; }
  .app-btn.primary {
    border: 1px solid #1B75BC;
    color: #1B75BC; }
    .app-btn.primary i {
      color: #fff;
      background: #1B75BC; }
    .app-btn.primary::before {
      background: #1B75BC; }
    .app-btn.primary:hover {
      color: #fff; }
  .app-btn.full-primary {
    border: 1px solid #1B75BC;
    background: #1B75BC; }
    .app-btn.full-primary i {
      color: #fff;
      background: #1B75BC; }
    .app-btn.full-primary::before {
      background: #fff; }
  .app-btn.with-outp {
    padding: 0 30px; }
    .app-btn.with-outp:hover::before {
      width: 100%; }
  .app-btn::before {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #fff;
    transition: width 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95); }
  .app-btn:hover {
    color: #1B75BC; }
    .app-btn:hover::before {
      width: calc(100% - 40px); }
  .app-btn:active {
    transform: scale(0.9); }

.app-form {
  position: relative;
  width: 100%; }
  .app-form-input {
    position: relative;
    width: 100%;
    height: auto; }
    .app-form-input span {
      font-size: 1.6em;
      user-select: none;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translate(0, -50%);
      transition: all .1s ease;
      color: #000; }
      .app-form-input span::after {
        content: ' *';
        transition: opacity .1s ease; }
  .app-form input:not(.file) {
    font-size: 1.6em;
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom: 1px solid #1B75BC;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    outline: 0;
    padding-left: 15px;
    color: #000;
    background: #ECF7FF; }
    .app-form input:not(.file):focus ~ span, .app-form input:not(.file):valid ~ span {
      font-size: 1em;
      top: 10px;
      left: 14px;
      color: #1B75BC; }
      .app-form input:not(.file):focus ~ span::after, .app-form input:not(.file):valid ~ span::after {
        opacity: 0; }
  .app-form .file {
    font-size: 16px; }
  .app-form select {
    font-size: 1.6em;
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom: 1px solid #1B75BC;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    outline: 0;
    padding-left: 15px;
    padding-right: 15px;
    color: #000;
    background: #ECF7FF; }
  .app-form label {
    font-size: 1.1em;
    opacity: .5;
    color: #000;
    margin-bottom: 0; }

.years-cards {
  display: flex;
  align-items: center;
  margin-top: 50px; }
  .years-cards img {
    object-fit: contain;
    object-position: center center;
    width: 90px;
    height: 90px;
    margin-right: 15px; }
  .years-cards h2 {
    font-weight: bold;
    font-size: 3.8em;
    line-height: .8;
    margin-bottom: 0; }
  .years-cards span:not(.main) {
    font-size: 2em; }
  @media only screen and (min-width: 768px) {
    .years-cards.main {
      margin-top: 0; }
      .years-cards.main img {
        margin-right: 30px; }
      .years-cards.main h2 {
        font-size: 7em; } }
  @media only screen and (max-width: 1199px) {
    .years-cards img {
      width: 80px;
      height: 80px; }
    .years-cards span {
      font-size: 1.8em; } }
  @media only screen and (max-width: 767px) {
    .years-cards {
      justify-content: center; } }

.bag-cards {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden; }
  .bag-cards .bag-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 5);
    height: 200px;
    bottom: -1px;
    right: -1px;
    padding: 0 15px;
    border-bottom: 1px solid #003056;
    border-right: 1px solid #003056;
    transition: background .3s ease; }
    .bag-cards .bag-card img {
      object-fit: contain;
      object-position: center center;
      max-width: 100%;
      width: 120px;
      max-height: 100px; }
    .bag-cards .bag-card:hover {
      background: #00305624; }
    @media only screen and (max-width: 991px) {
      .bag-cards .bag-card {
        width: calc(100% / 3); } }
    @media only screen and (max-width: 559px) {
      .bag-cards .bag-card {
        width: 50%;
        height: 120px; } }

.bag-cards.main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  padding: 0 15px;
  border-top: 1px solid #003056;
  border-left: 1px solid #003056;
  transition: background .3s ease; }
  .bag-cards.main img {
    object-fit: contain;
    object-position: center center;
    max-width: 100%;
    width: 120px;
    max-height: 100px; }
  .bag-cards.main:hover {
    background: #00305624; }
  @media only screen and (max-width: 559px) {
    .bag-cards.main {
      height: 120px; } }

.news-cards {
  width: 100%;
  height: auto; }
  .news-cards img {
    object-fit: cover;
    object-position: center top;
    width: 100%;
    height: 500px; }
  .news-cards .date {
    font-weight: bold;
    font-size: 1.8em;
    display: inline-block;
    margin: 20px 0;
    padding-left: 30px; }
  .news-cards h2 {
    font-size: 2.4em;
    padding: 0;
    margin-bottom: 20px; }
    .news-cards h2 span {
      padding-left: 30px; }
  .news-cards h4 {
    font-weight: 400;
    font-size: 1.8em;
    padding-left: 30px; }
  @media only screen and (max-width: 991px) {
    .news-cards img {
      height: 300px; }
    .news-cards .date {
      font-size: 1.6em; }
    .news-cards h2 {
      font-size: 2em; }
    .news-cards h4 {
      font-size: 1.6em; }
    .news-cards a {
      font-size: 1.6em; } }
  @media only screen and (max-width: 767px) {
    .news-cards .date,
    .news-cards h4 {
      padding-left: 0; }
    .news-cards h2 span {
      padding-left: 0; } }

.mission .col-lg-4 {
  margin-bottom: 50px; }
  .mission .col-lg-4 img {
    object-fit: contain;
    object-position: center center;
    width: 80px;
    height: 80px;
    margin-bottom: 20px; }
  .mission .col-lg-4 h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.4em;
    margin-bottom: 10px; }
  .mission .col-lg-4 p {
    font-size: 1.5em;
    margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .mission .col-lg-4 {
      text-align: center;
      margin-bottom: 20px; }
      .mission .col-lg-4 img {
        width: 70px;
        height: 70px;
        margin-bottom: 10px; }
      .mission .col-lg-4 h4 {
        font-size: 2em; } }

.advantages .col-md-6 {
  margin-bottom: 50px; }
  .advantages .col-md-6 img {
    object-fit: contain;
    object-position: left bottom;
    width: 100px;
    height: 100px;
    margin-bottom: 15px; }
  .advantages .col-md-6 h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.4em;
    margin-bottom: 15px; }
  .advantages .col-md-6 p {
    font-size: 1.8em;
    line-height: 1;
    padding-left: 25px;
    margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .advantages .col-md-6 {
      text-align: center;
      margin-bottom: 20px; }
      .advantages .col-md-6 img {
        width: 80px;
        height: 80px; }
      .advantages .col-md-6 h4 {
        font-size: 2em; }
      .advantages .col-md-6 p {
        font-size: 1.6em;
        padding-left: 0; } }

.subdivisions .row .col-12 {
  position: relative;
  display: flex;
  padding-left: 100px;
  margin-top: 50px; }
  .subdivisions .row .col-12 div {
    padding-left: 30px; }
  .subdivisions .row .col-12 img {
    object-fit: contain;
    object-position: left top;
    width: 100px;
    height: 100px; }
  .subdivisions .row .col-12 h4 {
    font-weight: bold;
    font-size: 2.4em;
    margin-bottom: 10px; }
  .subdivisions .row .col-12 p {
    font-size: 1.8em; }
  @media only screen and (min-width: 768px) {
    .subdivisions .row .col-12::before {
      content: '';
      position: absolute;
      width: 60px;
      height: 10px;
      top: 40px;
      left: -30px;
      z-index: 1;
      background: url("../img/sbd-line.png") no-repeat;
      background-size: cover;
      background-position: left top; }
    .subdivisions .row .col-12::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 150%;
      top: -30%;
      left: -26px;
      background: #fff; }
    .subdivisions .row .col-12:first-child {
      padding-left: 230px; }
      .subdivisions .row .col-12:first-child::before {
        top: 50%;
        width: 230px;
        transform: translateY(-50%); }
      .subdivisions .row .col-12:first-child::after {
        top: 50%; }
    .subdivisions .row .col-12:last-child::after {
      height: 100%;
      top: auto;
      bottom: calc(100% - 40px); } }
  @media only screen and (max-width: 767px) {
    .subdivisions .row .col-12 {
      text-align: center;
      flex-direction: column;
      align-items: center;
      padding-left: 15px; }
      .subdivisions .row .col-12 img {
        margin-bottom: 10px; }
      .subdivisions .row .col-12 div {
        padding: 0; } }
  @media only screen and (max-width: 559px) {
    .subdivisions .row .col-12 h4 {
      font-size: 2em; }
    .subdivisions .row .col-12 p {
      font-size: 1.6em; } }

.vacancies .row .col-md-6 {
  position: relative;
  top: 1px;
  right: -1px;
  padding: 60px;
  border-right: 1px solid #003056;
  border-bottom: 1px solid #003056; }

.vacancies .row h2 {
  font-size: 2.4em;
  height: 55.56px !important;
  padding: 0;
  margin-bottom: 20px;
  -webkit-line-clamp: 2;
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.vacancies .row p {
  font-size: 1.8em;
  height: 80px !important;
  margin-bottom: 50px;
  -webkit-line-clamp: 3;
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; }

@media only screen and (max-width: 767px) {
  .vacancies .row {
    text-align: center; }
    .vacancies .row .col-md-6 {
      padding: 50px 15px; }
    .vacancies .row h2 {
      font-size: 2em; }
    .vacancies .row p {
      font-size: 1.6em;
      margin-bottom: 30px; } }

.products .row.overflow-hidden .col-lg-6 {
  position: relative;
  top: 1px;
  right: -2px;
  padding: 60px;
  border-right: 1px solid #003056;
  border-bottom: 1px solid #003056; }

.products .row.overflow-hidden .d-flex {
  align-items: flex-end; }

.products .row.overflow-hidden img {
  object-fit: contain;
  width: 80px;
  height: 80px;
  margin-right: 20px; }

.products .row.overflow-hidden h2 {
  font-size: 2.4em;
  padding-bottom: 0; }

.products .row.overflow-hidden p {
  font-size: 1.8em;
  margin-bottom: 50px;
  transition: all 3s ease;
  -webkit-line-clamp: 2;
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden; }
  .products .row.overflow-hidden p.active {
    -webkit-line-clamp: 50;
    height: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; }

@media only screen and (max-width: 991px) {
  .products .row.overflow-hidden {
    text-align: center; }
    .products .row.overflow-hidden .col-lg-6 {
      padding: 50px 15px; }
    .products .row.overflow-hidden .d-flex {
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .products .row.overflow-hidden img {
      margin: 0;
      margin-bottom: 20px; }
    .products .row.overflow-hidden h2 {
      font-size: 2em; } }

.search-wrapper {
  position: relative;
  background: #ffffff1a;
  width: 250px;
  height: 40px;
  top: 0;
  z-index: 1;
  transition: all .3s ease;
  margin-top: 50px; }
  @media only screen and (max-width: 767px) {
    .search-wrapper {
      margin-top: 80px; } }
  .search-wrapper input {
    font-size: 1.4em;
    background: transparent;
    border: 0;
    color: white;
    outline: none;
    width: 100%;
    height: 40px;
    padding: 0 40px 0 13px;
    font-weight: 100;
    transition: 275ms ease; }
    .search-wrapper input::placeholder {
      color: #cccccc; }

#main {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #fff;
  transition: all .2s ease; }
  #main p {
    position: relative;
    margin: 30px 0; }
    #main p::before {
      position: absolute;
      content: '';
      width: 80%;
      height: 1px;
      bottom: -15px;
      left: 10%;
      background: linear-gradient(80deg, rgba(255, 255, 255, 0) 0%, #ffffffad 50%, rgba(255, 255, 255, 0) 100%); }

.search-input {
  width: 300px;
  max-width: 100%;
  border: none;
  outline: 0;
  background: #eee;
  border-radius: 4px;
  padding: 10px 30px; }

.app-drop {
  position: relative; }
  .app-drop-content {
    position: absolute;
    visibility: hidden;
    width: auto;
    min-width: 100%;
    max-width: 200%;
    height: auto;
    top: 100%;
    left: 50%;
    padding-top: 20px;
    opacity: 0;
    transform: translateX(-50%); }
    .app-drop-content p {
      line-height: 1;
      padding-bottom: 15px;
      margin: 0; }
      .app-drop-content p a {
        font-weight: 300 !important;
        font-size: 15px; }
        .app-drop-content p a::after {
          display: none; }
        .app-drop-content p a:hover {
          font-weight: 500 !important; }
    .app-drop-content.top {
      top: 0;
      padding-top: 0;
      padding-bottom: 5px;
      transform: translateY(calc(-100%)); }
      .app-drop-content.top p {
        padding: 0;
        padding-top: 15px;
        margin: 0; }
  .app-drop:hover .app-drop-content {
    visibility: visible;
    opacity: 1;
    transition: all .4s ease; }

.sec-tle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3.8em;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding-bottom: 55px;
  color: #003056; }
  .sec-tle span {
    padding-left: 50px; }
  @media only screen and (max-width: 991px) {
    .sec-tle {
      font-size: 3.2em; }
      .sec-tle span {
        padding-left: 25px; } }
  @media only screen and (max-width: 767px) {
    .sec-tle {
      text-align: center;
      display: inline-block;
      padding-bottom: 30px; }
      .sec-tle span {
        padding-left: 0; } }
  @media only screen and (max-width: 559px) {
    .sec-tle {
      font-size: 2.4em; } }

.link {
  font-weight: bold;
  font-size: 1.9em;
  display: inline-block;
  align-items: center;
  color: #1B75BC !important; }
  .link i {
    padding-left: 10px;
    transition: padding-left .2s ease; }
  .link:hover i {
    padding-left: 15px; }

.bg-text {
  text-transform: uppercase;
  text-align: right;
  font-weight: bold;
  font-size: 19.1vw;
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  right: 30px;
  z-index: -1;
  opacity: .1;
  transform: translateY(-50%); }
  .bg-text.top {
    top: 0;
    transform: none; }

.tle-text p {
  font-size: 1.8em; }

.wrapper .tabs {
  position: relative;
  display: flex;
  margin: 70px 0; }
  .wrapper .tabs .tab {
    font-size: 1.5em;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 30px;
    transition: all .1s ease;
    color: #003056; }
    .wrapper .tabs .tab::before {
      content: '';
      position: absolute;
      width: 0%;
      height: 3px;
      bottom: -13px;
      left: 0;
      transition: all .3s ease .1s;
      background: #1B75BC; }
    .wrapper .tabs .tab.active {
      font-weight: bold;
      font-size: 1.8em; }
      .wrapper .tabs .tab.active::before {
        width: 30%; }
  .wrapper .tabs::before {
    content: '';
    position: absolute;
    width: 3000px;
    height: 2px;
    bottom: -15px;
    left: 0;
    background: #1B75BC; }
  @media only screen and (max-width: 1199px) {
    .wrapper .tabs {
      flex-wrap: wrap;
      justify-content: space-around; }
      .wrapper .tabs .tab {
        margin: 0 .8rem; }
        .wrapper .tabs .tab::before {
          display: none; } }
  @media only screen and (max-width: 767px) {
    .wrapper .tabs {
      justify-content: space-between; } }

.wrapper .tab_item {
  display: none; }
  .wrapper .tab_item.active {
    display: block; }

.close {
  outline: none !important;
  border: 0; }

main {
  position: relative;
  width: 100%;
  height: auto; }
  main section {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 75px; }
    main section.py {
      padding: 75px 0; }
    main section.mt {
      padding-top: 0;
      margin-top: 75px; }
    main section.my {
      padding: 0;
      margin: 75px 0; }
    main section.outer {
      margin: 0;
      margin-top: 75px; }
      main section.outer.py {
        margin: 0;
        margin: 75px 0; }
    @media only screen and (max-width: 767px) {
      main section {
        padding-top: 75px; }
        main section.py {
          padding: 75px 0; }
        main section.mt {
          margin-top: 75px; }
        main section.my {
          margin: 75px 0; }
        main section.outer {
          margin: 0;
          margin-top: 75px; }
          main section.outer.py {
            margin: 0;
            margin: 75px 0; } }
  main .intro {
    overflow: hidden;
    min-height: 100vh;
    height: auto;
    background: url(../img/intro-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
    main .intro-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-height: 100vh;
      padding: 130px 0;
      padding-top: 250px;
      z-index: 1;
      color: #fff; }
      main .intro-item h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 4em;
        margin-bottom: 30px; }
      main .intro-item p {
        font-size: 1.8em;
        margin-bottom: 30px; }
      main .intro-item .app-search {
        font-weight: bold;
        font-size: 1.5em;
        display: flex;
        justify-content: flex-end;
        cursor: pointer; }
        main .intro-item .app-search .app-drop {
          position: relative;
          top: 9px; }
          main .intro-item .app-search .app-drop-content P {
            font-size: 14px; }
        main .intro-item .app-search-input {
          display: flex;
          align-items: center;
          padding-left: 15px; }
          main .intro-item .app-search-input input {
            border: 0;
            border-bottom: 1px solid #fff;
            outline: 0;
            color: #fff;
            background: transparent; }
          main .intro-item .app-search-input i {
            font-size: 1.2em;
            padding-left: 5px; }
      @media only screen and (max-width: 991px) {
        main .intro-item h1 {
          font-size: 3.2em; }
        main .intro-item p {
          font-size: 1.6em; } }
      @media only screen and (max-width: 767px) {
        main .intro-item .col-md-6 {
          text-align: center; }
        main .intro-item h1 > * {
          padding: 0 !important; }
        main .intro-item .app-search {
          justify-content: center;
          padding-top: 30px; } }
  main .success-years {
    font-weight: 400; }
    main .success-years p {
      font-size: 1.8em;
      margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      main .success-years {
        text-align: center; }
        main .success-years p {
          margin-bottom: 20px; } }
  main .news .row .col-md-5 {
    margin-top: 120px; }
    main .news .row .col-md-5:nth-child(even) {
      margin-top: 0; }
    @media only screen and (max-width: 767px) {
      main .news .row .col-md-5 {
        margin-top: 20px; }
        main .news .row .col-md-5:nth-child(even) {
          margin-top: 20px; }
        main .news .row .col-md-5:first-child {
          margin: 0; } }
  main .news p.main {
    text-align: right; }
    main .news p.main a {
      font-size: 2.2em; }
  main .callback .col-md-5 {
    margin-bottom: 30px; }
  main.about-company-page .box h2 {
    padding-bottom: 20px; }
  main.about-company-page .box p {
    font-size: 1.8em;
    padding-left: 50px; }
  @media only screen and (max-width: 991px) {
    main.about-company-page .box p {
      padding-left: 35px; } }
  @media only screen and (max-width: 767px) {
    main.about-company-page .box p {
      text-align: center;
      padding-left: 0; } }
  main.about-company-page .world-map {
    background: url("../img/world-map-bg.jpg") no-repeat;
    background-size: cover;
    background-position: center center; }
    main.about-company-page .world-map .container {
      position: relative; }
    main.about-company-page .world-map-svg {
      width: 100%;
      height: 800px; }
      main.about-company-page .world-map-svg .country {
        fill: #fff;
        transition: all .3s ease; }
        main.about-company-page .world-map-svg .country:hover {
          fill: #00345a; }
    main.about-company-page .world-map .coutry-titles {
      font-size: 1.5em;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      bottom: -15px;
      color: #fff; }
      main.about-company-page .world-map .coutry-titles li {
        list-style-type: none;
        user-select: none;
        font-size: 16px; }
        main.about-company-page .world-map .coutry-titles li::before {
          font-family: 'Font Awesome 5 Pro';
          content: '\f3c5';
          font-weight: 900;
          font-size: 0.8em;
          margin-right: 5px; }
    @media only screen and (max-width: 767px) {
      main.about-company-page .world-map-svg {
        height: 290px; }
      main.about-company-page .world-map .coutry-titles {
        display: flex;
        justify-content: center;
        position: static;
        padding-top: 20px; }
        main.about-company-page .world-map .coutry-titles .col-6 {
          flex: 0;
          width: auto; }
        main.about-company-page .world-map .coutry-titles ul {
          padding: 0;
          margin: 0; } }
  main.about-company-page .mission {
    overflow: hidden; }
    main.about-company-page .mission .bg-img {
      position: absolute;
      object-fit: cover;
      object-position: center center;
      width: 30%;
      height: 750px;
      top: 300px;
      left: 0; }
      main.about-company-page .mission .bg-img.right {
        left: auto;
        right: 0; }
      main.about-company-page .mission .bg-img.top {
        top: 0; }
      @media only screen and (max-width: 767px) {
        main.about-company-page .mission .bg-img {
          display: none; } }
    main.about-company-page .mission .container:not(.main) {
      position: relative;
      padding: 75px 100px;
      z-index: 1;
      color: #fff;
      margin-top: 75px;
      background: #1b75bcde; }
      main.about-company-page .mission .container:not(.main) h2 {
        color: #fff; }
      @media only screen and (max-width: 559px) {
        main.about-company-page .mission .container:not(.main) {
          padding: 50px 15px; } }
    @media only screen and (max-width: 767px) {
      main.about-company-page .mission {
        padding-bottom: 0; } }
  main.about-company-page .advantages .row {
    justify-content: center; }
  main.trading-page .trading .col-md-6 {
    position: static; }
    main.trading-page .trading .col-md-6:last-child {
      padding-left: 50px; }
  main.trading-page .trading h2 {
    padding-bottom: 30px; }
  main.trading-page .trading p {
    font-size: 1.6em; }
    main.trading-page .trading p i {
      font-weight: 500;
      font-style: italic;
      display: block;
      padding-left: 40px;
      opacity: .8; }
      main.trading-page .trading p i a {
        color: #1B75BC; }
  main.trading-page .trading .app-btn {
    margin-left: 40px; }
  main.trading-page .trading .box {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0; }
    main.trading-page .trading .box img {
      object-fit: cover;
      max-height: 100%;
      height: auto; }
      main.trading-page .trading .box img:first-child {
        object-position: right bottom;
        width: 50%;
        margin-top: 200px; }
      main.trading-page .trading .box img:last-child {
        object-position: left bottom;
        width: 35%; }
  @media only screen and (max-width: 767px) {
    main.trading-page .trading {
      text-align: center; }
      main.trading-page .trading .col-md-6:first-child {
        display: none; }
      main.trading-page .trading .col-md-6:last-child {
        padding-left: 0; }
      main.trading-page .trading p i {
        padding-left: 0; }
      main.trading-page .trading .app-btn {
        margin: 0; } }
  main.news-item-page .news-item img {
    width: 100%;
    height: auto;
    margin-bottom: 80px; }
  main.news-item-page .news-item h2:not(.main) {
    font-size: 2.4em;
    padding: 0;
    margin-bottom: 30px; }
  main.news-item-page .news-item .box {
    margin-bottom: 50px; }
    main.news-item-page .news-item .box span {
      font-weight: bold;
      font-size: 1.8em;
      margin-bottom: 20px; }
    main.news-item-page .news-item .box:last-of-type {
      margin: 0; }
  main.news-item-page .news-item p,
  main.news-item-page .news-item li {
    font-size: 16px; }
  main.news-item-page .news-item p.column {
    font-size: 1.8em;
    column-count: 1;
    column-gap: 60px;
    margin-bottom: 30px; }
  main.news-item-page .news-item i.main {
    font-size: 1.8em;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 55%;
    margin: 0 auto 100px;
    color: #1B75BC; }
    main.news-item-page .news-item i.main b {
      margin-top: 20px;
      color: #003056; }
    main.news-item-page .news-item i.main::before {
      content: '“';
      font-weight: bold;
      font-size: 30em;
      font-style: normal;
      line-height: .7;
      position: absolute;
      user-select: none;
      top: 0;
      right: 0;
      opacity: .1;
      z-index: -1;
      color: #000; }
  main.news-item-page .news-item p.italic {
    font-style: italic;
    font-weight: 500;
    font-size: 1.8em;
    margin-bottom: 30px;
    opacity: .7; }
  @media only screen and (max-width: 767px) {
    main.news-item-page .news-item img {
      object-fit: cover;
      height: 350px;
      margin-bottom: 30px; }
    main.news-item-page .news-item .box {
      padding-left: 0; }
      main.news-item-page .news-item .box span {
        text-align: right;
        font-size: 1.6em;
        display: block;
        width: 100%; }
    main.news-item-page .news-item h2:not(.main) {
      font-size: 1.8em; }
    main.news-item-page .news-item p.column,
    main.news-item-page .news-item p.italic {
      font-size: 1.6em; }
    main.news-item-page .news-item i.main {
      text-align: center;
      font-size: 1.6em;
      width: 100%;
      margin-bottom: 30px; }
    main.news-item-page .news-item p.column {
      column-count: 1; } }
  main.news-item-page .news-item.second img {
    height: 300px;
    object-fit: cover;
    object-position: center; }
  main.vendor-item-page .vendor-item .box .back {
    font-weight: bold;
    font-size: 1.9em;
    display: block;
    width: 100%;
    color: #1B75BC;
    margin-bottom: 50px; }
    main.vendor-item-page .vendor-item .box .back i {
      margin-right: 10px; }
  main.vendor-item-page .vendor-item .box img {
    width: 450px;
    max-width: 100%;
    margin-bottom: 50px; }
  main.vendor-item-page .vendor-item .box p {
    font-size: 1.8em;
    margin-bottom: 20px; }
  main.vendor-item-page .vendor-item .box .links {
    user-select: none;
    pointer-events: none;
    font-size: 1.8em;
    color: #1B75BC;
    margin: 30px 0 100px; }
    main.vendor-item-page .vendor-item .box .links a {
      margin-right: 20px; }
      main.vendor-item-page .vendor-item .box .links a.active {
        text-decoration: underline; }
  main.vendor-item-page .vendor-item .box .downloads h3 {
    font-size: 2.4em;
    margin-bottom: 30px; }
  main.vendor-item-page .vendor-item .box .downloads .download {
    font-weight: bold;
    font-size: 1.5em;
    display: flex;
    flex-wrap: wrap; }
  main.vendor-item-page .vendor-item .box .downloads a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-right: 50px;
    margin-bottom: 15px;
    color: #1B75BC;
    background: rgba(0, 48, 86, 0.1); }
    main.vendor-item-page .vendor-item .box .downloads a img {
      width: 22px;
      margin: auto 0;
      margin-right: 10px; }
  @media only screen and (max-width: 767px) {
    main.vendor-item-page .vendor-item .box .downloads,
    main.vendor-item-page .vendor-item .box .links {
      text-align: center; }
    main.vendor-item-page .vendor-item .box .links {
      margin: 20px 0 50px; } }
  main.vendor-item-page .video iframe {
    margin-bottom: 30px; }
  main.vendor-item-page .video .date {
    font-weight: bold;
    font-size: 1.8em;
    display: block;
    padding-left: 30px;
    margin-bottom: 30px; }
  main.vendor-item-page .video .title {
    font-size: 2.4em;
    padding-bottom: 10px; }
  main.vendor-item-page .video p {
    font-size: 1.8em;
    padding-left: 30px; }
  @media only screen and (max-width: 767px) {
    main.vendor-item-page .video iframe {
      margin-bottom: 30px; }
    main.vendor-item-page .video .date {
      font-size: 1.6em;
      margin-bottom: 30px;
      padding-left: 0; }
    main.vendor-item-page .video .title {
      font-size: 2em; }
    main.vendor-item-page .video p {
      font-size: 1.6em;
      padding-left: 0; } }
  main .region {
    text-align: center; }
    main .region .col-lg-2 {
      font-weight: bold;
      font-size: 1.8em;
      margin-bottom: 30px; }
      main .region .col-lg-2 img {
        width: 100%;
        margin-bottom: 25px; }
    main .region hr {
      margin: 50px 0 70px;
      background: #003056; }
  main.contacts-page .contacts .tab_content h3 {
    font-weight: bold;
    font-size: 2.4em;
    margin-bottom: 50px; }
  main.contacts-page .contacts .tab_content p {
    font-size: 1.8em;
    margin-bottom: 20px; }
  main.contacts-page .contacts .tab_content img {
    width: 75px;
    margin-bottom: 50px; }
  main.contacts-page .contacts .tab_content .next {
    font-weight: bold;
    font-size: 1.9em;
    display: block;
    width: 100%;
    color: #1B75BC;
    margin-bottom: 50px; }
    main.contacts-page .contacts .tab_content .next i {
      padding-left: 10px; }
  @media only screen and (max-width: 991px) {
    main.contacts-page .contacts .tab_content h3 {
      font-size: 2em;
      margin-bottom: 30px; }
    main.contacts-page .contacts .tab_content p {
      font-size: 1.6em; }
    main.contacts-page .contacts .tab_content img {
      margin-bottom: 30px; } }
  main.contacts-page .contacts .tabs {
    justify-content: flex-start; }
  main.page {
    padding-top: 140px; }

footer {
  position: relative;
  overflow: hidden;
  padding: 100px 0;
  background: url("../img/footer-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center center; }
  footer h2 {
    padding-bottom: 20px !important; }
  footer p {
    font-size: 1.8em;
    margin-bottom: 40px;
    color: #fff; }
  footer ul {
    text-align: right;
    padding: 0;
    margin: 0; }
    footer ul li {
      list-style-type: none;
      margin-top: 10px; }
      footer ul li:last-child {
        margin-top: 50px; }
        footer ul li:last-child a {
          position: relative; }
          footer ul li:last-child a::after {
            position: absolute;
            content: '';
            width: 35%;
            height: 3px;
            bottom: -10px;
            left: 0;
            background: #fff; }
    footer ul a {
      font-size: 1.8em;
      position: relative;
      color: #fff !important; }
      footer ul a::after {
        position: absolute;
        content: '';
        width: 0;
        height: 3px;
        bottom: -8px;
        left: 0;
        transition: all .3s ease;
        background: #fff; }
      footer ul a:hover {
        font-weight: 500; }
        footer ul a:hover::after {
          width: 50%; }
  @media only screen and (max-width: 767px) {
    footer {
      text-align: center; }
      footer p {
        font-size: 1.6em;
        margin-bottom: 30px; }
      footer ul {
        text-align: center;
        margin-top: 30px; } }

header {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  padding: 100px 0;
  z-index: 4;
  transition: all .3s ease; }
  header nav {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between; }
  header.active {
    padding: 10px 0;
    background: rgba(0, 0, 0, 0.578);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px); }
    header.active .brand {
      height: 80px; }
  header .brand {
    transition: all .3s ease; }
  header ul.main {
    position: relative;
    display: flex;
    width: auto;
    color: #fff;
    padding: 0;
    margin: 0;
    margin-left: 1rem; }
    header ul.main li {
      position: relative;
      display: flex;
      align-items: center;
      list-style: none;
      margin-left: 2rem; }
      header ul.main li a {
        position: relative;
        font-size: 1.5em;
        transition: all .2s linear; }
        header ul.main li a::after {
          position: absolute;
          content: '';
          width: 0;
          height: 3px;
          bottom: -11px;
          left: 0;
          transition: all .3s ease;
          background: #fff; }
        header ul.main li a.active {
          font-weight: 500; }
          header ul.main li a.active::after {
            width: 50%; }
      header ul.main li i {
        font-size: 1.4em;
        cursor: pointer; }
      header ul.main li:hover a {
        font-weight: 500; }
        header ul.main li:hover a::after {
          width: 50%; }
      header ul.main li:first-child::before {
        position: absolute;
        content: '';
        width: 3000px;
        height: 3px;
        bottom: -10px;
        left: 0;
        background: #fff; }
    header ul.main .app-drop-content a {
      font-size: 1.5em; }
    @media only screen and (max-width: 1247px) {
      header ul.main li {
        margin-left: 1rem; }
        header ul.main li a {
          font-size: 1.8em; } }
    @media only screen and (max-width: 991px) {
      header ul.main {
        display: none; } }
  header.primary {
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.578);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px); }
    header.primary nav {
      align-items: flex-end; }
    header.primary .app-drop-content {
      padding: 10px;
      padding-top: 20px;
      padding-bottom: 0;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #fff; }
    header.primary ul.main {
      margin-bottom: 9px;
      color: #1B75BC; }
      header.primary ul.main li a::after {
        background: #1B75BC; }
      header.primary ul.main li:first-child::before {
        background: #1B75BC; }
  @media only screen and (max-width: 991px) {
    header {
      padding: 20px 0 0;
      z-index: 10; }
      header .brand {
        height: 70px; } }

.mob-nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
  transform: translateY(-100%);
  transition: all .4s ease;
  background: #000000e0; }
  .mob-nav ul {
    padding: 0;
    padding: 100px 15px;
    margin: 0; }
    .mob-nav ul li {
      cursor: pointer;
      text-align: center;
      font-size: 2.4em;
      line-height: 1.8;
      list-style-type: none;
      color: #fff; }
  .mob-nav .collapse-item {
    text-align: center;
    font-size: 14px;
    position: relative;
    display: none;
    width: 100%;
    height: auto;
    padding: 15px 0;
    color: #fff; }
    .mob-nav .collapse-item p {
      margin-bottom: 10px; }
  .mob-nav.active {
    transform: translateY(0); }

.ham {
  width: 50px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  .ham.primary span {
    background: #1B75BC; }
  @media only screen and (min-width: 992px) {
    .ham {
      display: none; } }

.ham span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #fff;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .15s ease;
  -moz-transition: .15s ease;
  -o-transition: .15s ease;
  transition: .15s ease; }

.ham span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0; }

.ham span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px; }

.ham span:nth-child(1), .ham span:nth-child(2) {
  top: 0px; }

.ham span:nth-child(3), .ham span:nth-child(4) {
  top: 16px; }

.ham span:nth-child(5), .ham span:nth-child(6) {
  top: 33px; }

.ham.active span:nth-child(1), .ham.active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.ham.active span:nth-child(2), .ham.active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.ham.active span:nth-child(1) {
  left: 5px;
  top: 9px; }

.ham.active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 9px; }

.ham.active span:nth-child(3) {
  left: -50%;
  opacity: 0; }

.ham.active span:nth-child(4) {
  left: 100%;
  opacity: 0; }

.ham.active span:nth-child(5) {
  left: 5px;
  top: 24px; }

.ham.active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 24px; }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-1 .swiper-wrapper,
.swiper-3 .swiper-wrapper,
.swiper-4 .swiper-wrapper {
  width: calc(100% - 1px);
  top: -1px;
  right: -3px; }
  .swiper-1 .swiper-wrapper .swiper-slide-active .bag-cards,
  .swiper-3 .swiper-wrapper .swiper-slide-active .bag-cards,
  .swiper-4 .swiper-wrapper .swiper-slide-active .bag-cards {
    border-left: 0; }

.swiper-1 .swp-dots,
.swiper-3 .swp-dots,
.swiper-4 .swp-dots {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .swiper-1 .swp-dots .swiper-pagination-bullet,
  .swiper-3 .swp-dots .swiper-pagination-bullet,
  .swiper-4 .swp-dots .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 7px;
    opacity: 1 !important;
    border: 1px solid #1B75BC;
    background: transparent; }
    .swiper-1 .swp-dots .swiper-pagination-bullet-active,
    .swiper-3 .swp-dots .swiper-pagination-bullet-active,
    .swiper-4 .swp-dots .swiper-pagination-bullet-active {
      background: #1B75BC; }

.swiper-2 {
  margin-top: 100px; }
  .swiper-2 .swiper-wrapper {
    position: relative; }
  .swiper-2 .swiper-slide {
    transition: all .3s ease; }
    .swiper-2 .swiper-slide p {
      font-weight: 300;
      font-size: 1.6em;
      margin: 0;
      transition: all .3s ease; }
    .swiper-2 .swiper-slide .slide-tle {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      top: 0;
      margin-bottom: 50px;
      transition: all .3s ease; }
      .swiper-2 .swiper-slide .slide-tle span {
        position: relative;
        display: inline-block;
        font-weight: 500;
        font-size: 1.6em;
        opacity: 1;
        transition: all .3 ease;
        color: #fff; }
        .swiper-2 .swiper-slide .slide-tle span:last-child {
          height: 23.33px; }
          .swiper-2 .swiper-slide .slide-tle span:last-child::before {
            position: absolute;
            content: '';
            width: 0%;
            height: 3px;
            top: calc(100% + 5px);
            left: 0;
            background: #fff;
            transition: all .3s ease;
            transition-delay: .3s; }
            @media only screen and (max-width: 559px) {
              .swiper-2 .swiper-slide .slide-tle span:last-child::before {
                left: 50%;
                transform: translateX(-50%); } }
    .swiper-2 .swiper-slide.swiper-slide-active .slide-tle span:last-child::before {
      width: 30%; }
    .swiper-2 .swiper-slide.swiper-slide-prev {
      opacity: 0; }
    .swiper-2 .swiper-slide:not(.swiper-slide-active) p {
      opacity: 0; }
    .swiper-2 .swiper-slide:not(.swiper-slide-active) .slide-tle {
      top: 24px; }
      .swiper-2 .swiper-slide:not(.swiper-slide-active) .slide-tle span:last-child {
        opacity: 0; }
  .swiper-2 .swp-dots {
    font-size: 2em;
    position: absolute;
    top: 65px;
    right: 0;
    z-index: 1;
    transition: all .3s ease;
    color: #fff; }
    .swiper-2 .swp-dots i {
      margin-left: 10px; }
      .swiper-2 .swp-dots i.swiper-button-disabled {
        opacity: .5; }
    @media only screen and (max-width: 559px) {
      .swiper-2 .swp-dots {
        left: 50%;
        transform: translateX(-50%); } }
  @media only screen and (min-width: 560px) {
    .swiper-2 .swiper-wrapper {
      left: -127.5px; } }
  @media only screen and (min-width: 768px) {
    .swiper-2 .swiper-wrapper {
      left: -258.75px; } }
  @media only screen and (min-width: 992px) {
    .swiper-2 .swiper-wrapper {
      left: -348.75px; } }
  @media only screen and (min-width: 1200px) {
    .swiper-2 .swiper-wrapper {
      left: -416.25px; } }
  .swiper-2::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    top: 55px;
    left: 0;
    background: #fff; }

.introSlider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; }
  .introSlider .swiper-pagination {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0); }
    .introSlider .swiper-pagination .swiper-pagination-bullet {
      margin: 0 2px;
      background: #fff; }
  .introSlider img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .introSlider ~ .container {
    z-index: 2; }

.breadcrumb {
  padding: 0;
  background: transparent; }
  .breadcrumb li {
    font-size: 1.6em; }
    .breadcrumb li a {
      font-weight: 500;
      color: #003056; }

.app-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  visibility: hidden;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  transition: all .2s ease;
  background: #000000e0; }
  .app-modal .close-modal {
    font-size: 24px;
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    z-index: 1;
    color: #fff; }
    @media only screen and (max-width: 559px) {
      .app-modal .close-modal {
        left: 50%;
        top: -45px;
        right: auto;
        transform: translateX(-50%); } }
  .app-modal-body {
    text-align: center;
    position: relative;
    padding: 15px;
    padding-top: 40px;
    border-radius: 5px;
    background: #fff; }
    .app-modal-body h3 {
      font-weight: 500;
      font-size: 3.2em; }
    .app-modal-body img {
      max-width: 100%;
      width: 150px;
      margin-top: 20px; }
  .app-modal.active {
    visibility: visible;
    opacity: 1; }

.modal-1 .close-modal {
  top: 15px; }

.modal-2 .close-modal {
  top: 13px;
  right: 15px !important;
  color: #003056; }

.world {
  position: absolute;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  max-width: 80%;
  max-height: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform-origin: 0 0 !important;
  transform: translate(-50%, -50%);
  animation: worldRotate 100s linear infinite; }

@keyframes worldRotate {
  0% {
    transform: rotate(0) translate(-50%, -50%); }
  100% {
    transform: rotate(360deg) translate(-50%, -50%); } }
