.app-btn
{
    font-weight: bold;
    font-size: 1.4em;
    letter-spacing: 1px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: auto;
    height: 44px;
    padding: 0 20px;
    padding-right: 54px;
    border: 1px solid #fff;
    border-right: 0;
    border-radius: 5px;
    outline: none!important;
    z-index: 1;
    color: #E5E5E5;
    background: transparent;
    transition: all .2s cubic-bezier(0.1, 1.26, 0.57, 1.32);

    i
    {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        top: 0;
        right: -2px;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: $primary;
        background: #fff;
    }

    &.primary
    {
        border: 1px solid $primary;
        color: $primary;

        i
        {
            color: #fff;
            background: $primary;
        }

        &::before
        {
            background: $primary;
        }

        &:hover
        {
            color: #fff;
        }
    }

    &.full-primary
    {
        border: 1px solid $primary;
        background: $primary;

        i
        {
            color: #fff;
            background: $primary;
        }

        &::before
        {
            background: #fff;
        }
    }

    &.with-outp
    {
        padding: 0 30px;

        &:hover
        {
            &::before
            {
                width: 100%;
            }
        }
    }

    &::before
    {
        position: absolute;
        content: '';
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: #fff;
        transition: width .2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    }

    &:hover
    {
        color: $primary;

        &::before
        {
            width: calc(100% - 40px);
        }
    }

    &:active
    {
        transform: scale(.9);
    }
}