.app-modal
{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    opacity: 0;
    transition: all .2s ease;
    background: #000000e0;

    .close-modal
    {
        font-size: 24px;
        position: absolute;
        cursor: pointer;
        top: 15px;
        right: 15px;
        z-index: 1;
        color: #fff;

        @include mq('phablet', max)
        {
            left: 50%;
            top: -45px;
            right: auto;
            transform: translateX(-50%);
        }
    }

    &-body
    {
        text-align: center;
        position: relative;
        padding: 15px;
        padding-top: 40px;
        border-radius: 5px;
        background: #fff;

        h3
        {
            font-weight: 500;
            font-size: 3.2em;
        }

        img
        {
            max-width: 100%;
            width: 150px;
            margin-top: 20px;
        }
    }

    &.active
    {
        visibility: visible;
        opacity: 1;
    }
}

.modal-1
{
    .close-modal
    {
        top: 15px;
    }  
}

.modal-2
{
    .close-modal
    {
        top: 13px;
        right: 15px!important;
        color: $purple;
    }
}