/**
  Переменные для цветов
 */

$primary: #1B75BC;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #17a2b8;
$dark: #343a40;
$muted: #707070;
$light: #f8f9fa;
$white: #fff;
$purple: #003056;
$black: #000;
$brown: #AC7D75;