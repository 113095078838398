.breadcrumb 
{
    padding: 0;
    background: transparent;

    li
    {
        font-size: 1.6em;

        a
        {
            font-weight: 500;
            color: $purple;
        }
    }
}