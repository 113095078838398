// Gotham Pro ====================================================================

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro-Light.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro-Italic.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro-Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro-Medium.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro-MediumItalic.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro-MediumItalic.woff) format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro-Bold.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro-Bold.woff) format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face
{
    font-family: "gotham-pro";
    src: url(../fonts/GothamPro/GothamPro-Black.ttf) format("truetype"),
         url(../fonts/GothamPro/GothamPro-Black.woff) format("woff");
    font-weight: 900;
    font-style: normal;
}